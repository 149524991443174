<template>
	<div>
		<f-card>
			<div
				v-html="$t('pages.settings.verticalActivityRel.help.keywordsNew')"
				class="status-alert"></div>
			<v-text-field
				outlined
				dense
				:label="$t('labels.keywords')"
				v-model="word.name" />
			<v-select
				v-model="word.vertical_id"
				outlined
				dense
				:items="list"
				:label="$t('labels.selectColumn')" />
		</f-card>

		<f-actions-btn-holder>
			<v-btn elevation="0" large @click="back">
				{{ $t("labels.btnCancel") }}
			</v-btn>
			<v-btn color="primary" elevation="0" large @click="save">
				{{ $t("labels.btnSave") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import {
	loadWord,
	loadVerticals,
	saveWord,
} from "./../../../services/verticals";
import { SETTINGS_ROUTES } from "./../../../router/settings";
export default {
	computed: {
		word() {
			return this.$store.state.verticals.view.word;
		},
		list() {
			return this.$store.state.verticals.list.map((item) => ({
				text: item.title,
				value: item.id,
			}));
		},
	},
	created() {
		this.getData();
	},
	methods: {
		async getData() {
			await loadVerticals();

			if (this.$route.name == SETTINGS_ROUTES.VERTICALS_REL_WORDS_EDIT) {
				const id = this.$route.params.id;
				loadWord(id);
			} else {
				this.$store.commit("verticals/resetView");
			}
		},
		back() {
			this.$router.push({
				name: SETTINGS_ROUTES.VERTICALS_REL_WORDS_LIST,
			});
		},
		async save() {
			await saveWord();
			this.back();
		},
	},
};
</script>

<style lang="scss">
.status-alert {
	background-color: #cccccc;
	border-radius: 3px;
	margin-bottom: 24px;
	padding: 12px 24px;
	font-weight: normal;
}
</style>
