<template>
	<div>
		<global-menu />

		<f-default-header
			:title="$t('pages.settings.subtitle.keywords')"
			:subtitle="
				$t('pages.settings.verticalActivityRel.subtitle.configRelation')
			" />

		<word-form />
	</div>
</template>

<script>
import WordForm from "../../../components/settings/verticalActivityRel/WordForm.vue";
import GlobalMenu from "../../../components/settings/verticalActivityRel/GlobalMenu.vue";

export default {
	components: {
		WordForm,
		GlobalMenu,
	},
};
</script>
